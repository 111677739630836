
jQuery(document).ready(function() {
	jQuery('.js-has-submenu').on('mouseover', function(e) {
		$(this).find('.sub-menu').fadeIn();
	});
 	
 	$(document).click(function(){
	  $(".header-navigation--primary .sub-menu").fadeOut();
	});

	/* Clicks within the dropdown won't make
	   it past the dropdown itself */
	$(".header-navigation--primary .sub-menu, .js-toggle-menu").click(function(e) {
	  e.stopPropagation();
	});

	$('.js-toggle-menu').on('click', function() {
		$('.js-header-navigation--primary').slideToggle();
	});

}); 



